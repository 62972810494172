import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Logo from '../Image/Logo';
import PortfolioContext from '../../context/context';

const Nav = () => {
  const { footer, logo } = useContext(PortfolioContext);
  const { img } = logo;
  const { networks } = footer;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);
  return (
    <nav
      id="nawigacja"
      style={{ transition: '1s ease', backgroundColor: navBackground ? '#333333' : 'transparent'}}
      className="navbar sticky-top navbar-fixed-top navbar-expand-lg"
    >
       <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      <div className="container">
        <Link to="/">
          <a className="navbar-brand" href="#">
            <Logo alt="logo" filename={img} />
          </a>
        </Link>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/">
                <a className="nav-link" href="#about">
                  O nas
                  <span className="sr-only">(current)</span>
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/realizacje">
                <a className="nav-link" href="#projects">
                  Nasze realizacje
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/oferta">
                <a className="nav-link" href="#">
                  Oferta
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/kontakt">
                <a className="nav-link" href="#contact">
                  Kontakt
                </a>
              </Link>
            </li>
          </ul>
          <div className="social-links m-0">
            {networks &&
              networks.map(network => {
                const { id, name, url } = network;
                return (
                  <a
                    key={id}
                    href={url}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label={name}
                  >
                    <i className={`fa m-0 p-0 fa-${name || 'refresh'} fa-inverse`} />
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
