import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Logo from '../Image/Logo';
import { GoMail } from 'react-icons/go';
import { GiSmartphone } from 'react-icons/gi';


const Footer = () => {
  const { footer, logo } = useContext(PortfolioContext);
  const { img } = logo;
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <div className="row">
          <div className="col-lg-4" >
          <div className="col-lg-4 text-left">
          <h2 className="text-uppercase text-nowrap">BRAND SODA SYSTEM</h2>
          <h3 className="text-uppercase text-nowrap">Marek Migas</h3>
          <h3 className="text-uppercase text-nowrap">Piasty Wielkie 15</h3>
          <h3 className="text-uppercase text-nowrap">11-220 Górowo Iławeckie</h3>
          <h3 className="text-uppercase text-nowrap"><GiSmartphone /> 533-917-983</h3>
          <h3 className="text-lowercase text-nowrap"><GoMail /> biuro@bssoda.pl</h3>
            
          </div>
          </div>
          <div className="col-lg-4 align-self-end">
            <a href="#top" aria-label="Back To Top" className="back-to-top">
              <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
            </a><br/>
            <Logo alt="logo" filename={img} />
            <div className="social-links">
              {networks &&
                networks.map(network => {
                  const { id, name, url } = network;
                  return (
                    <a
                      key={id}
                      href={url}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={name}
                    >
                      <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                    </a>
                  );
                })}
            </div>
            <div className="align-items-end">
            <hr />
            <p className="footer__text">
              © {new Date().getFullYear()} - BSS.{' '} Wykonanie{' '}
              <a href="mailto:marekkuziola@o2.pl" target="_blank" rel="noopener noreferrer">
                Marek Kuzioła
              </a>
            </p>
            </div>
          </div>
          <div className="col-lg-4 text-center">
          <h2 className="text-uppercase">Linki</h2>
            <ul className="list-unstyled">
              <li>
                <a className="text-white" href="#">O nas</a>
              </li>
              <li>
                <a className="text-white" href="#">Oferta</a>
              </li>
              <li>
                <a className="text-white" href="#">Nasze realizacje</a>
              </li>
              <li>
                <a className="text-white" href="#">Cennik</a>
              </li>
              <li>
                <a className="text-white" href="#">Kontakt</a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default Footer;
